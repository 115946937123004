<template>
  <div style="width: 1200px;margin: 0 auto">
    <div style="width: 100%;height: 75px;display: flex;align-items: center">
      <div style="width: 1200px;border-bottom: 1px solid #fc7c40;height: 40px">
        <div class="tixing" style="width: auto;padding-right: 30px">
          {{ data.name }}
        </div>
<!--        <span style="float: right;display: inline-block;cursor: pointer" @click="goStoreList">更多>></span>-->
      </div>
    </div>
    <div class="rmzl_content" style="height: auto">
      <a :href="data.ads[0].url"><img :src="data.ads[0].pic" alt="" height=""></a>

<!--      <div class="ktzjtj" style="border:none;height: auto;padding: 20px;padding-left:0;padding-right:0;padding-bottom:0;justify-content: flex-start;display: flex;flex-wrap: wrap" >-->
<!--        <div class="per_gaoxiao" style="cursor: pointer;margin-left: 18px"-->
<!--             @click="goStoreDetail(item.id)"-->
<!--             v-for="item in data && data.labels[0].objs.length>6?data.labels[0].objs.slice(0,6):data.labels[0].objs"-->
<!--             :key="item.id">-->
<!--          <div class="touxiang_name">-->
<!--            <div class="touxiang">-->
<!--              <img :src="item.pic" alt="" height="90" width="100">-->
<!--              &lt;!&ndash;              <Avatar icon="ios-person" size="70" style="margin: 0 auto" :src="item.pic"/>&ndash;&gt;-->
<!--            </div>-->
<!--            <div style="float: left;margin-left: 30px;margin-top: 10px" class="ellipse-1">-->
<!--              <div style="  font-size: 20px;font-weight: bold;-->
<!--  font-family: 'PingFang';-->
<!--                                color: rgb(40, 43, 69);margin-bottom: 10px;max-width: 200px;width: 180px" class="ellipse-1">-->
<!--                {{item.name}}-->
<!--              </div>-->
<!--              <div style="text-align: left">-->
<!--                <span style="color: #FDCE63">//////</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="gaoxiao_intro">-->
<!--            <span class="ellipse-7" style="height: 98%;width: 100%;    display: -webkit-box;-->
<!--    overflow: hidden;-->
<!--    text-overflow: ellipsis;-->
<!--    word-wrap: break-word;-->
<!--    white-space: normal !important;-->
<!--    -webkit-line-clamp: 5;-->
<!--    -webkit-box-orient: vertical">-->
<!--                    {{item.introduction || '暂无简介'}}-->
<!--            </span>-->


<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'singleAD',
  props: {
    data: Object
  },
  data() {
    return {}
  },
  methods: {
    goStoreList() {
      this.$router.push({
        path: this.data.moreUrl
      })
      scrollTo(0,0)
    },
    goStoreDetail(id) {
      this.$router.push({
            path: '/home/serviceOutStoreList',
            query: {
              shopId: id
            }
          }
      )
    }
  }
}
</script>

<style >
.gaoxiao_intro {
  width: 100%;
  height: 170px;
  background-color: #f7f8FC;
  padding: 20px;
}

.per_gaoxiao {
  flex-shrink: 0;
  height: 320px;
  width: 375px;
  border: 1px solid #f5f5f5;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 30px;
}

.touxiang_name {
  height: 80px;
  margin-bottom: 20px;
}

.touxiang_name .touxiang {
  float: left;
  height: 80px;
}
.ivu-avatar>img {
  height: 100%!important;
}
.per_gaoxiao:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1)!important;
}

</style>
